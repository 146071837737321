 <template>
  <div style="height:100%">
    <div style="padding: 20px;height: 800px">
      <!-- 搜素框 -->
      <el-form :inline="true" :model="params" style="padding-top:20px;padding-left:20px;margin-bottom:20px;background-color:#fff;border-radius:8px" size="small">
        <el-form-item label="订单编号">
          <el-input v-model="params.orderNum" placeholder="请输入订单编号"></el-input>
        </el-form-item>
        <el-form-item label="安装地址">
          <el-input v-model="params.detailAddress" placeholder="请输入安装地址"></el-input>
        </el-form-item>
        <el-form-item label="结算时间">
          <el-date-picker v-model="params.createTimeEndLong" type="datetime" placeholder="开始时间" value-format="timestamp" style="width: 180px;"></el-date-picker>
          <el-date-picker default-time="23:59:59" v-model="params.createTimeStartLong" type="datetime" placeholder="结束时间" value-format="timestamp" style="margin-left:5px;width: 180px;"></el-date-picker>
        </el-form-item>
        <el-form-item label="所属师傅">
          <el-select filterable  v-model="params.masterId" placeholder="请选择">
            <el-option v-for="item in masterList" :key="item.id" :label="(item.userName).substring(item.userName.indexOf('('), item.userName.indexOf(','))" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="checkData" type="primary" size="mini">查询</el-button>
          <el-button type="info" @click="reset" size="mini">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <div style="height:82%;background-color:#fff;border-radius:8px;">
        <div style="width:98%;margin: auto;height: 80%">
          <el-button type="success" size="small" style="float:right;margin-top:20px;margin-bottom:20px" @click="toExcel">导出Excel</el-button>
          <el-table :data="tableData" stripe height="100%" size="small" :header-cell-style="{'background-color':'#84aace4a','color':'#343333','text-align':'center'}" :cell-style="{'text-align':'center'}">
            <el-table-column prop="orderNum" label="订单编号" width="200"></el-table-column>
            <el-table-column prop="orderPrice" label="上游订单费用" width="100"></el-table-column>
            <el-table-column prop="profitPrice" label="最终盈利总费用" width="110"></el-table-column>
            <el-table-column prop="orderProfitPrice" label="订单盈利费用" width="100"></el-table-column>
            <el-table-column prop="additionsPrice" label="增项费用"></el-table-column>
            <el-table-column prop="additionsProfitPrice" label="增项盈利费用" width="100"></el-table-column>
            <el-table-column prop="masterName" label="负责师傅"></el-table-column>
            <el-table-column prop="masterOrderPrice" label="师傅订单所得费用" width="120"></el-table-column>
            <el-table-column prop="masterAdditionsPrice" label="师傅增项所得费用" width="120"></el-table-column>
            <el-table-column prop="masterPrice" label="师傅最终结算费用" width="120"></el-table-column>
            <el-table-column prop="salesmanPrice" label="业务员最终结算费用" width="130"></el-table-column>
            <el-table-column prop="detailAddress" label="安装地址"></el-table-column>
            <el-table-column prop=" userName" label="结算人"></el-table-column>
            <el-table-column prop="createTime" label="结算时间" :formatter="dateFormat" width="140"></el-table-column>
          </el-table>
          <div style="padding-top:20px;height:40px;background-color:#fff;width: 100%;border-radius:8px;text-align:right">
            <el-pagination background :page-sizes="sizes" layout="total,sizes,prev,pager,next" :total="total" @current-change="handleCurrentChange" @size-change="sizeChange"></el-pagination>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import moment from 'moment'
export default {
  data() {
    return {
      tableData: [],
      masterList:[],
      params: {
        createTimeEndLong:"",
        createTimeStartLong:"",
        detailAddress:"",
        masterId:"",
        orderNum:"",
        page: 1,
        size: 10,
      },
      total: 0,
      sizes: [10, 15],
    };
  },
  created() {
    this.querySettleList();
    this.queryMasterList();
  },
  methods: {
    queryMasterList(){//所属师傅下拉（包含暂不接单的师傅）
      this.axios({
        method: "get",
        url: "/v1/web/user-info/select-master-drown"
      }).then((res) => {
        this.masterList = []
        let obj = {}
        obj.id = 0;
        obj.userName = "全部("
        this.masterList.push(obj)
        this.masterList.push(... res.data.data.list)
      });
    },
    querySettleList(){//获取列表信息
      this.axios({
        method: "get",
        url: "/v1/web/order-info/select-settlement",
        params: this.params,
      }).then((res) => {
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
      });
    },
    toExcel(){//获取列表信息
      this.axios({
        method: "get",
        url: "/v1/web/order-info/export-settlement",
        params: this.params,
        responseType: "blob"
      }).then((res) => {
        let filename=res.headers['content-disposition']
        const link=document.createElement("a");
        let blob=new Blob([res.data], { type: "application/octet-stream" }); //类型excel
        link.style.display="none";
        link.href=URL.createObjectURL(blob);
        link.setAttribute("download", decodeURI(filename.substring(filename.indexOf("=")+1)));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    dateFormat:function(row){
      if(row.createTime !== null && row.createTime !== undefined){
        return moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
      }
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.querySettleList();
    },
    sizeChange(val) {
      this.params.size = val;
      this.params.page = 1;
      this.querySettleList();
    },
    checkData() {
      this.querySettleList();
    },
    reset() {
      this.params = { page: 1, size: 10 };
      this.querySettleList();
    },
  },
};
</script>

<style scoped>

</style>
